<template>
  <div
    id="YSAppointment"
    
  >
  <h1 style="color:orange" class="w-full sm:w-auto text-center mt-5 mb-5"> {{$t('YearSummary')}} </h1>
   <div > 
    <Calendar style="height:500px" :display-week-number="true" :data-source="sessionDataYear" @year-changed="yearChanged" @mouse-on-day="mouseOnDay" @mouse-out-day="mouseOutDay"></Calendar>
</div>
   
  </div>
</template>
<script>
import Calendar from 'v-year-calendar';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; 
import 'tippy.js/themes/light-border.css';
import moduleDoctor from "@/store/doctor/moduleDoctor.js";

export default {
  data() {
    return {
      search:{},
      sessionDataYear:[]
    };
  },
  components:{
    Calendar,
  },
  methods: {
    getYearSummary(){
      var model = {};
      model.Year = this.search.Year;
      model.DoctorID = this.$store.state.AppActiveUser.Doctor.ID;
 this.$vs.loading();
      this.$store
        .dispatch("DoctorList/getDoctorClendarYearSummary",  model)
        .then((res) => {
          this.sessionDataYear = res.data.Data.map(function (val){
              val.startDate = new Date(val.startDate);
              val.endDate = new Date(val.endDate);
              return val;
          });

          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
      yearChanged: function(e) {
      this.search.Year=e.currentYear;
      this.getYearSummary(e.currentYear);
    },
     mouseOnDay: function(e) {
      if (e.events.length > 0) {
        var content = '';

        for (var i in e.events) {
          content += '<div class="event-tooltip-content">'
            + '<div class="event-name" style="color:' + e.events[i].color + '">' + e.events[i].name + '</div>'            
            + '</div>';
        }

        if (this.tooltip != null) {
          this.tooltip.destroy();
          this.tooltip = null;
        }

        this.tooltip = tippy(e.element, {
            placement: 'top',
            content: content,            
            theme: 'light-border',
            animation: 'shift-away',
            arrow: true
        });
        this.tooltip.show();
      }
    },
    mouseOutDay: function() {
      if (this.tooltip !== null) {
        this.tooltip.destroy();
        this.tooltip = null;
      }
    },
  },
  created() {
    debugger
     if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
     this.search.Year = 2021;
     this.getYearSummary();
   
  },
};
</script>
<style scoped>

</style>